import { util } from "/modules/util.js";
import { html, HTMLTemplateResult, nothing } from "/modules/vendor/lit.js";

import { LitElement } from "/modules/vendor/lit.js";
import { customElement, state } from "/modules/vendor/lit/decorators.js";

import "/modules/vendor/@material/mwc-icon.js";
import { sessionStorageUtil } from "/modules/session-storage-util.js";

import tailwind from "/modules/tailwind.css.js";

export enum MessageType {
	Info,
	Success,
	Warning,
	Error,
	Help,
	// adds info text automatically.
	// TODO report to a support backend? maybe create github issue
	InternalError, // TODO or FatalError?
}

export enum NotificationType {
	Toast, // with timeout, for example on successful save
	Notification, // no timeout, for example when user has to read the message
	Dialog, // modal with actions
}

class Notification {
	messageType!: MessageType;
	title!: string;
	description?: string | HTMLTemplateResult;

	constructor(
		messageType: MessageType,
		title: string,
		description?: string | HTMLTemplateResult
	) {
		this.messageType = messageType;
		this.title = title;
		this.description = description;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"komit-notifier": Notifier;
	}
}

@customElement("komit-notifier")
export class Notifier extends LitElement {
	static styles = [tailwind];

	@state()
	private notifications: Notification[] = [];

	reloadAndNotify(
		messageType: MessageType,
		title: string,
		description?: string
	): void {
		sessionStorageUtil.set<Notification>(sessionStorageUtil.notifierKey, {
			messageType: messageType,
			title: title,
			description: description,
		});
		window.location.reload();
	}

	checkForMessageAfterReload(): void {
		const notification = sessionStorageUtil.get<Notification>(
			sessionStorageUtil.notifierKey
		);
		sessionStorageUtil.set(sessionStorageUtil.notifierKey, {});
		if (notification?.messageType && notification?.title) {
			this.notify(
				notification.messageType,
				notification.title,
				notification.description
			);
		}
	}

	notify(
		messageType: MessageType,
		title: string,
		description?: string | HTMLTemplateResult
	) {
		let notification = new Notification(messageType, title, description);
		this.notifications.unshift(notification);

		// TODO requestUpdate shouldn't be necessary and is not necessary when notify is called from within component
		// 		for example for tests in connectedCallback
		// 		but if notify is called via util.notify, requestUpdate is necessary so that they show
		// 		however, closing on timeout works as expected
		this.requestUpdate();
		this.updateComplete.then(() => {
			let timeout = this.timeout(messageType);
			if (timeout > 0) {
				setTimeout(() => {
					this.close(notification);
				}, timeout);
			}
		});
	}

	closeAllWithoutTimeout() {
		this.notifications = this.notifications.filter(
			(notification) => this.timeout(notification.messageType) != -1
		);
	}

	connectedCallback() {
		super.connectedCallback();

		this.checkForMessageAfterReload();

		/*
		this.notify(MessageType.Info, "first", "info");
		this.notify(MessageType.Success, "second", "success");
		this.notify(MessageType.Warning, "third", "warning");
		this.notify(MessageType.Error, "forth", "error");
		this.notify(MessageType.Help, "fifth", "help");
		 */
	}

	private timeout(messageType: MessageType): number {
		switch (messageType) {
			case MessageType.Success:
				return 2500;
			case MessageType.Info:
			case MessageType.Warning:
				return 7500;
			case MessageType.Help:
			case MessageType.Error:
			case MessageType.InternalError:
				return -1;
		}
	}

	private iconColorClass(messageType: MessageType): string {
		switch (messageType) {
			case MessageType.Info:
			case MessageType.Help:
				return "text-blue-400";
			case MessageType.Success:
				return "text-green-400";
			case MessageType.Warning:
				return "text-yellow-400";
			case MessageType.Error:
			case MessageType.InternalError:
				return "text-red-400";
		}
	}

	private icon(messageType: MessageType): string {
		switch (messageType) {
			case MessageType.Info:
				return "info_outline";
			case MessageType.Success:
				return "check_circle_outline";
			case MessageType.Warning:
			case MessageType.Error:
			case MessageType.InternalError:
				return "error_outline";
			case MessageType.Help:
				return "help_outline";
		}
	}

	private close(notification: Notification) {
		this.notifications = this.notifications.filter((notificationx) => {
			return notificationx != notification;
		});
	}

	render() {
		return html`
			<div
				class="pointer-events-none fixed inset-0 flex items-end justify-center px-4 py-6 sm:items-start sm:justify-end sm:p-6"
			>
				<div class="w-full max-w-xs">
					${this.notifications.map(
						(notification) => html`
							<div
								@click="${this.close.bind(this, notification)}"
								class="pointer-events-auto mb-4 cursor-pointer overflow-hidden rounded-sm bg-purple-50 shadow-lg ring-1 ring-black ring-opacity-5"
							>
								<div class="p-4">
									<div class="flex items-start">
										<div class="flex-shrink-0">
											<mwc-icon
												class="${this.iconColorClass(
													notification.messageType
												)} h-6 w-6"
												>${this.icon(
													notification.messageType
												)}</mwc-icon
											>
										</div>
										<div class="ml-3 w-0 flex-1 pt-0.5">
											<p
												class="text-sm font-medium text-gray-900"
											>
												${notification.title}
											</p>
											<p
												class="mt-1 text-sm text-gray-500"
											>
												${notification.description}
											</p>
											${notification.messageType ==
											MessageType.InternalError
												? html`
														<p
															class="mt-1 text-sm text-gray-500"
														>
															${util.tr(
																"Please contact the support if this error occurs again."
															)}
														</p>
												  `
												: nothing}
										</div>
										<!-- 
											all notifications (also with timeout) can be closed with click anywhere, 
											but close button is just shown on notifications that have to be closed manually (without timeout).
											this provides a better user experience because the user known immediately if he/she has to close it manually or not
											
											it's important that there is no click handler on button, because otherwise the close handler would be called twice
											for each click, one time on the button, on one time of the element and thus two elements would get closed even if we 
											click just on one...
										-->
										${this.timeout(
											notification.messageType
										) <= 0
											? html`
													<div
														class="ml-4 flex flex-shrink-0"
													>
														<button
															class="inline-flex rounded-sm text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														>
															<span
																class="sr-only"
																>Close</span
															>
															<!-- Heroicon name: solid/x -->
															<svg
																class="h-5 w-5"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
																fill="currentColor"
																aria-hidden="true"
															>
																<path
																	fill-rule="evenodd"
																	d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
																	clip-rule="evenodd"
																/>
															</svg>
														</button>
													</div>
											  `
											: nothing}
									</div>
								</div>
							</div>
						`
					)}
				</div>
			</div>
		`;
	}
}
